<template>
    <div class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
      <button @click="prevImage" v-show="currentIndex > 0" class="previous"><img class="btns" src="/back.svg"/></button>
      <img :src="currentImage" alt="lightbox" v-if="images.length > 0">
      <button @click="nextImage" v-show="currentIndex < images.length - 1" class="next"><img class="btns" src="/forward.svg"/></button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LightboxGallery',
    data() {
      return {
        server: process.env.VUE_APP_SERVER_URL,
        currentIndex: 0,
        images: []
      };
    },
    props: {
      imageArray: Array,
      projectId: String,
      closeLightbox: Function,
    },
    computed: {
      currentImage() {
        return this.images[this.currentIndex];
      }
    },
    methods: {
      nextImage() {
        if (this.currentIndex < this.images.length - 1) {
          this.currentIndex++;
        }
      },
      prevImage() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
      }
    },
    created() {
        // this.images = this.imageArray;

        this.imageArray.forEach((item) => {
            this.images.push(this.server + '/images/' + this.projectId + '/' + item);
            console.log(this.server + '/images/' + this.projectId + '/' + item);
        });

        /*
                  <div v-if="!loading" class="grid-item" :style="{ backgroundImage: 'url(' + server + '/images/' + projectId + '/' + project[0].PictureUrls[0] + ')' }">
                <!-- No need for img tag here -->
            </div>
        */
    },
  };
  </script>
  
  <style scoped>
  .lightbox {
    text-align: center;
  }
  .lightbox img {
    max-width: 100%;
    max-height: 80vh;
  }
  .previous{
    position: absolute;
    left: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  .next{
    position: absolute;
    right: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  .btns{
    background: none;
    border: none;
    width: 30px;
  }
  </style>
  